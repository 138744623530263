<template>
  <div class="validate-token d-flex justify-content-center align-items-center w-100 text-center">
    <div v-if="!formError()" class="align-middle">
      <b-spinner class="m-auto" variant="info" style="width: 5rem; height: 5rem;"></b-spinner>
      <p class="mt-2">Validating Token...</p>
    </div>

    <div v-else-if="formError().code === 'invalidInitToken'">
      Invalid initialization token. Please <b-link to="/reset-password">request initialization</b-link> or contact The Larkin Company for assistance, <ClickToCallMain/>.
    </div>
    <div v-else-if="formError().code === 'expiredInitToken'">
      Expired initialization token. Please <b-link to="/reset-password">request initialization</b-link> or contact The Larkin Company for assistance, <ClickToCallMain/>.
    </div>
    <div v-else-if="formError().code === 'invalidPasswordResetToken'">
      Invalid password reset token. Please <b-link to="/reset-password">request initialization</b-link> or contact The Larkin Company for assistance, <ClickToCallMain/>
    </div>
    <div v-else-if="formError().code === 'expiredPasswordResetToken'">
      Expired password reset token. Please <b-link to="/reset-password">request initialization</b-link> or contact The Larkin Company for assistance, <ClickToCallMain/>.
    </div>
    <div v-else>
      {{formErrorMessage()}} <ClickToCallMain/>.
    </div>
  </div>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import FormMixin from '@/mixins/formMixin'
  import ClickToCallMain from "../components/ClickToCallMain";

  export default {
    name: 'initialize',
    components: {
      ClickToCallMain,
    },
    props: ['token', 'tokenType'],
    mixins: [FormMixin],
    methods: {
      validateToken() {
        this.submit('post', ApiProperties.api.validateToken, {}, {
            token: this.token,
            tokenType: this.tokenType
          },
          (response) => {
            this.$root.updateAuthStatus(response.data);
            this.$root.navigateToDefaultRoute();
          });
      }
    },
    created: function() {
      this.validateToken();
    }
  }
</script>

<style lang="scss">
.validate-token {
  a {
    color: #66bbe6;
  }
}
</style>
